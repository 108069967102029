@import "../../App.scss";

// .contact {
// min-height: calc(80vh - 5rem);
/* padding-top: 0; */
// background: linear-gradient(
//     rgba(255, 255, 255, 0.7),
//     rgba(255, 255, 255, 0.7)
//   ),
//   url("/assets/images/contact-us.jpg") center/cover scroll no-repeat;

//above image url path not working!
// background-image: url("http://localhost:3000/assets/images/contact-us.jpg"); //worked!
// }

.contact-center {
  max-width: $max-width;
  padding: 1rem;
}

.contact-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  margin-top: 2rem;
  padding: 0 1rem;
  h4 {
    color: $clr-grey-1;
    letter-spacing: 0.1rem;
    line-height: 1.5rem;
  }
  span {
    font-weight: bold;
    color: $clr-primary-3;
  }
}

@media screen and (min-width: 776px) {
  .contact {
    min-height: 100%;
  }
  .contact-container {
    margin: 2rem auto;
    max-width: 70%;
  }
}
