@import "../../App.scss";
footer {
  min-height: 8rem;
  background: $clr-grey-1;
  color: $clr-grey-6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  h4 {
    letter-spacing: 0.1rem;
    padding: 0.5rem 0.5rem 1rem;
  }
  .footer-name {
    color: $clr-primary-5;
  }
}
.contact-links {
  padding: 1rem 0 0.5rem;
  a {
    color: $clr-white;
    font-size: 2rem;
    margin: 0.5rem;
    padding: 0.5rem;
    transition: color 0.3s ease-in-out;
  }
  a:hover {
    color: $clr-primary-5;
  }
}

@media screen and (min-width: 776px) {
  footer h4 {
    letter-spacing: $spacing;
  }
}
