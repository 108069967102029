@import "../../App.scss";
.card {
  box-shadow: $dark-shadow;
  background-color: $clr-white;
  transition: 0.3s;
  margin: 1rem 0.5rem;
  // max-height: 30rem;
  height: 30rem;
  width: 20rem;
  // width: 45%;
  border-radius: $radius;
  overflow: hidden;
  &:hover {
    box-shadow: $darker-shadow;
  }
}

.card-image-container {
  border: 0.6rem solid transparent;
  position: relative;
  height: 12rem;
  img {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, $clr-primary-5, #222);
    opacity: 0.85;
    transition: $transition;
  }
}

.card:hover .card-image-container::after {
  opacity: 0;
}

.card-image-container .view-app {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  height: 100%;
  width: 100%;
  cursor: poiner;
}

.card-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  width: 100%;
  // height: 50%;
  height: 18rem;
  h4 {
    color: $clr-grey-1;
    font-weight: bold;
  }

  h5 {
    text-align: justify;
    text-justify: auto;
  }

  .project-links a {
    font-size: 1.5rem;
    margin: 0.5rem;
  }
}
