@import "./variables";

/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh !important; //not working
  font-family: $ff-primary;
  color: $clr-grey-1;
  line-height: 1.5rem;
  font-size: 0.875rem;
  overflow-x: hidden;
  min-width: 100vw;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
img:not(.nav-logo) {
  width: 100%;
  display: block;
}

h1,
h2,
h3,
h4 {
  letter-spacing: $spacing;
  // text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
  font-family: $ff-primary;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
h5 {
  margin-bottom: 1.25rem;
  color: $clr-grey-4;
}
p {
  margin-bottom: 1.25rem;
  color: $clr-grey-4;
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

.section {
  max-width: $max-width;
  margin: 0 auto;
  padding: 1rem 0.5rem;
}

.max-height {
  min-height: calc(100vh - 13rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  text-transform: capitalize;
  font-size: 1.3rem;
  color: $clr-grey-1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem 2rem;
  /* margin: 0 auto 1.25rem auto; */
  text-align: center;
}
.title-underline {
  height: 0.3rem;
  width: 5rem;
  margin-top: 0.5rem;
  background-color: $clr-primary-5;
}

/* all icons except footer */
.icons a {
  color: $clr-grey-1;
  transition: color 0.3s ease-in-out;
  padding: 0.4rem 0.4rem 0.2rem;
  &:hover {
    color: $clr-primary-4;
  }
  &:active {
    border: 0.2rem dashed $clr-primary-4;
  }
}

.home-btn {
  padding: 0.5rem 0.7rem;
  margin: 1rem auto 0;
  display: block;
  font-size: 1rem;
  font-weight: bold;
  text-transform: capitalize;
  background-color: $clr-primary-4;
  border: 0.15rem solid $clr-primary-4;
  border-radius: $radius;
  transition: $transition;
  a {
    color: $clr-white;
    letter-spacing: 0.15rem;
    text-decoration: none;
  }
  &:hover {
    background-color: $clr-primary-4;
    a {
      color: #fff;
    }
  }
  &:active {
    transform: scale(0.98);
  }
}

@media screen and (min-width: 776px) {
  .section {
    padding: 1rem 1rem 3rem 1rem;
  }
  .profile-img {
    display: block;
  }
  .home-btn {
    display: none;
  }
}
