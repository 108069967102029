@import "../../App.scss";

.about {
  // background-color:;
  /* min-height: 100vh; */
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 100%;
}

.about-image {
  display: none;
}

.about-text {
  padding: 0 1rem 3rem;
  flex: 0 1 60%;
  .title {
    margin-top: 1rem;
  }
  p {
    text-align: justify;
    text-justify: auto;
  }
  ul {
    columns: 2;
    margin: 0.7rem 0;
    list-style-type: none;
    .icon {
      margin-right: 0.5rem;
      color: $clr-primary-5;
    }
  }
}

@media screen and (min-width: 776px) {
  .about-content {
    flex-direction: row;
    margin: 2rem auto;
  }
  .about-image {
    display: block;
    max-width: 400px;
  }
}
