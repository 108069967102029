@import "../../App.scss";
.banner {
  background-color: $clr-primary-10;
  height: calc(100% - 5rem);
  width: 100vw;
}

.banner-center {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-evenly;
  max-width: $max-width;
  margin: 0 auto;
  height: calc(100vh - 5rem);
}

.banner-info {
  margin: 1rem;
  h1 {
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1.25rem;
  }
  .social-links {
    margin-top: 2rem;
    font-size: 2rem;
    a {
      margin: 0.5rem;
    }
  }
}

@media screen and (min-width: 576px) {
  .banner-info h1 {
    font-size: 3rem;
  }
  .banner-center {
    flex-direction: row;
  }
}
